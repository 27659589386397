@import "../../styles/colours.scss";
@import "../../styles/variables.scss";

footer {
  background-color: $tan-100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px;
  font-size: 13px;
  color: $tan-600;

  @media print {
    display: none;
  }

  a {
    font-weight: bold;
    color: $tan-700;
    // text-decoration: none;
  }

  h3 {
    margin-top: 12px;
    margin-bottom: 4px;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 8px;
  }

  .footer-top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  #contact-info {
    margin-right: 32px;
    width: 100%;

    &,
    h2,
    h3 {
      color: $tan-800;
    }

    h2 {
      margin-top: 0;

      @media screen and (max-width: $mobile-breakpoint) {
        margin-bottom: 0;
      }
    }
  }

  #contact-info-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    & > div + div {
      margin-left: 48px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;

      & > div + div {
        margin-left: 0;
      }
    }
  }

  #privacy-notice {
    border-top: 1px solid $tan-200;
    padding-top: 16px;
    margin-top: 24px;
    text-align: justify;
    font-size: 11px;
  }

  .footer-social-media-btns {
    margin-top: 12px;

    a {
      color: $tan-800;
      transition: all 0.3s;
      width: 24px;
      display: inline-block;
      text-align: center;

      &:hover {
        color: $tan-900;
      }

      svg {
        font-size: 24px;
      }
    }

    a + a {
      margin-left: 6px;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 10px;
    padding: 18px;

    #contact-info {
      margin-right: 0;
    }

    #privacy-notice {
      margin-top: 12px;
      padding-top: 12px;
      font-size: 9px;
    }
  }
}
