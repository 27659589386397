@import "../../../styles/colours.scss";
@import "../../../styles/variables.scss";

#our-story {
  max-width: 720px;
  margin: 0 auto;

  th {
    vertical-align: top;
  }

  th,
  td {
    padding: 8px;
  }
}

#core-faith-values {
  th {
    padding-right: 4px;
    color: $tan-700;
  }

  th::after {
    content: "—";
    margin-left: 8px;
  }
}

.about-bfec-btns {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin-top: 32px;
  }

  .info-btn {
    flex: 1;
    font-size: 48px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    color: $grey-200;
    margin: 12px;

    &:hover {
      color: $grey-300;
    }

    &.info-btn-selected {
      color: $blue-400;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 32px;
    }
  }

  .about-bfec-btns-content {
    border: 1px solid $grey-100;
    border-radius: 8px;
    padding: 32px;

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 18px;
    }
  }
}
