// Neutrals
$grey-040: hsla(206, 4%, 67%, 0.153);
$grey-050: #f7f7f7;
$grey-100: #e1e1e1;
$grey-200: #cfcfcf;
$grey-300: #b1b1b1;
$grey-400: #9e9e9e;
$grey-500: #7e7e7e;
$grey-600: #626262;
$grey-700: #515151;
$grey-800: #3b3b3b;
$grey-900: #222222;
$grey-1000: #161616;
$black: #000000;

// $grey-050: #f0f4f8;
// $grey-100: #d9e2ec;
// $grey-200: #bcccdc;
// $grey-300: #9fb3c8;
// $grey-400: #829ab1;
// $grey-500: #627d98;
// $grey-600: #486581;
// $grey-700: #334e68;
// $grey-800: #243b53;
// $grey-900: #102a43;

$blue-050: hsl(208, 45%, 93%);
$blue-100: hsl(208, 42%, 80%);
$blue-200: hsl(208, 40%, 73%);
$blue-300: hsl(208, 37%, 65%);
$blue-400: hsl(208, 35%, 58%);
$blue-500: hsl(208, 29%, 49%);
$blue-600: hsl(208, 35%, 42%);
$blue-700: hsl(208, 40%, 35%);
$blue-800: hsl(208, 47%, 28%);
$blue-900: hsl(208, 50%, 21%);
$blue-1000: hsl(208, 54%, 14%);

$tan-050: hsl(33, 64%, 95%);
$tan-100: hsl(33, 58%, 90%);
$tan-200: hsl(33, 52%, 85%);
$tan-300: hsl(33, 46%, 80%);
$tan-400: hsl(33, 40%, 75%);
$tan-500: hsl(33, 34%, 70%);
$tan-500a: hsla(33, 34%, 85%, 0.7);
$tan-600: hsl(33, 34%, 65%);
$tan-700: hsl(33, 34%, 60%);
$tan-800: hsl(33, 34%, 50%);
$tan-900: hsl(33, 34%, 40%);

$bkk: #4d0606;
$bkk-light: hsl(0, 86%, 32%);
$purple: hsl(268, 100%, 61%);

$card-500: hsla(44, 100%, 58%, 0.9);
// Navigation Bar / Dropdown Menu & Footer
$navbar: #e5d9cae6;
$navbar-hover: #decebae6;
$navbar-solid: #e5d9ca;
$navbar-solid-hover: #deceba;
$nav-text: #474542;
$nav-hover: #3d7aa8;
// Buttons
$btn: #f59e67;
$btn-hover: #f59e67a6;
$btn-text: white;
// Whats On
$announcement-card: hsl(41, 100%, 91%);

$who-is-jesus-background: rgb(19, 19, 19);
