@import "../../../styles/colours.scss";
@import "../../../styles/variables.scss";

#ministry-desktop-select {
  display: flex;

  &.white-card {
    padding-top: 24px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

#ministry-mobile-select {
  display: none;
  @media screen and (max-width: $mobile-breakpoint) {
    display: block;
  }
}

#ministries-selector {
  margin-top: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin: 36px 0;
    gap: 12px;
    flex-direction: column;

    .btn {
      width: 180px;
    }
  }
}

#ministries-selector-body h1 {
  font-family: "Pharllos", sans-serif;
  font-size: 72px;
  color: $blue-500;
  text-align: center;
  margin-bottom: 64px;
  font-weight: normal;
}

.ministry-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  #frontpage-ministries-all {
    color: $blue-200;
    font-size: 18px;
    margin-top: 48px;
    text-transform: none;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1100px;
  }

  a {
    text-decoration: none;
    font-size: 26px;
    font-weight: bold;
    padding: 14px 32px;
    margin: 0;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 3px;
    }

    &.active {
      color: white;
      text-decoration: none;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 22px;
      padding: 10px 14px;
    }
  }
}

#people-ministries {
  padding-top: 64px;
  width: 100%;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-top: 18px;
  }
}

.people-ministries-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  transition: all 0.5s;

  .ministry-image {
    padding: 80px 30px;

    img {
      max-width: 400px;
      // border-radius: 3px;
      border: 1px solid white;
    }
  }

  .ministry-text {
    display: flex;
    flex-direction: column;
    padding: 80px 30px;
    max-width: 500px;
    line-height: 1.75em;
    color: white;

    .min-details {
      display: flex;
      flex-direction: column;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 28px;
        color: white;
      }

      .mission,
      .vision {
        margin: 5px 0 5px 0;

        p {
          padding: 2px 0 2px 0;
          margin: 0;
        }

        ul {
          margin: 0;
        }
      }

      h4 {
        margin: 0;
        padding: 0;
      }

      .meet-details {
        margin: 24px 0;
        background-color: rgba(255, 255, 255, 0.12);
        color: rgba(255, 255, 255, 0.85);
        // border-radius: 5px;
        padding: 20px;

        .meet-detail {
          display: flex;
          align-items: center;
          font-size: 0.9em;
          line-height: 1.27em;

          .icons {
            margin-right: 8px;
            min-width: 25px;
          }
        }

        .meet-detail + .meet-detail {
          margin-top: 16px;
        }
      }

      .min-text {
        margin: 10px 0 10px 0;

        p {
          line-height: 1.75em;
        }
      }
    }

    .contact {
      a {
        color: white;
      }
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    .ministry-image {
      padding: 0;

      img {
        max-width: unset;
        width: 100vw;
      }
    }

    .ministry-text {
      padding: 18px 24px;
    }
  }
}

hr {
  width: 100%;
}

.fade-enter {
  opacity: 0.8;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.fade-exit,
.fade-exit-active {
  display: none;
}

#serving-ministries {
  padding: 64px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 18px;
  }

  .ministry-boxes {
    display: grid;
    grid-gap: 12px;

    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $mobile-breakpoint) {
      grid-template-columns: 1fr;
    }

    .ministry-box {
      background-color: $tan-050;
      padding: 32px;

      display: flex;
      flex-direction: column;

      .ministry-name {
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 8px;
      }

      .ministry-blurb {
        font-style: italic;
        color: $grey-300;
        margin-bottom: 12px;
        flex: 1;
      }

      @media screen and (max-width: $mobile-breakpoint) {
        padding: 18px;

        .ministry-name {
          font-size: 16px;
        }
      }
    }
  }
}
