@import "../../styles/variables.scss";
@import "../../styles/colours.scss";

#visit-us {
  display: flex;
  flex-direction: row;
  padding: 12px 32px;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 48px;

  #visit-us-service {
    flex: 1;
    margin-right: 48px;
  }

  #visit-us-details {
    th {
      padding-right: 18px;
      color: $tan-600;
      font-weight: normal;
    }
  }

  .meeting-details {
    th {
      text-align: right;
      padding-right: 14px;
      width: 64px;
      color: $tan-600;
      font-weight: normal;
    }

    th,
    td {
      padding-bottom: 3px;
    }
  }

  #map {
    height: 300px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    padding: 0px 12px;

    #visit-us-service {
      margin-right: 0;
    }
  }
}
