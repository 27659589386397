@import "../../../styles/colours";
@import "../../../styles/variables";

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;

  height: 50px;

  .listing-filters-btn {
    background-color: $grey-050;
    border-radius: 18px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: $grey-100;
    }

    svg {
      font-size: 14px;
    }
  }

  .search-input {
    display: flex;

    input {
      transform-origin: right;
      transform: scaleX(0);
    }

    @media screen and (max-width: $mobile-breakpoint) {
      flex: 1;

      input {
        width: 100%;
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 0;

    .clear-filter {
      width: 100%;
    }
  }

  $panel-padding: 16px;

  .search-panel {
    position: absolute;
    top: 54px;
    right: 46px;
    width: 320px;
    box-sizing: border-box;
    padding-top: $panel-padding;
    //padding-bottom: 12px;
    border: 1px solid $grey-100;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
    z-index: 100;

    opacity: 0;

    .search-list + .search-list {
      margin-top: 18px;
    }

    .list-title {
      font-size: 16px;
      color: $grey-500;
      padding: 0 $panel-padding;
      margin-bottom: 2px;
    }

    .search-list-item,
    .no-search-items {
      padding: 12px $panel-padding;
      display: block;
    }

    .search-list-item {
      text-decoration: none;
      color: black;
      font-weight: bold;
      transition: all 0.3s;

      &:hover {
        color: $blue-700;
      }
    }

    .search-list-item-selected {
      background-color: $blue-050;
    }

    .no-search-items {
      color: $grey-300;
      font-size: 15px;
      text-align: center;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      right: 0;
      width: 100%;
    }
  }
}
