@import "../../../styles/variables";
@import "../../../styles/colours";

.listing {
  flex: 1;
}

.listing-search-with-padding {
  .listing {
    padding: 32px;

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 16px;
    }
  }
}

.filter-blurb-container {
  display: flex;
  flex-direction: row;
  background-color: $grey-050;
  margin-top: 12px;
  line-height: 1.5em;

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }
}

.filter-blurb {
  padding: 24px;
  flex: 2;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 18px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 10px;
    flex: 1;
  }

  .close-btn {
    padding: 4px;
    color: $grey-400;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: $grey-600;
    }
  }

  p {
    margin-bottom: 0;
  }
}
