@import "../../../styles/colours";
@import "../../../styles/variables";

.post {
  max-width: 1200px;
  margin: 52px auto;
  padding: 18px;
  font-size: 18px;
  overflow: auto;

  @media screen and (max-width: $mobile-breakpoint) {
    margin: 12px auto;
  }

  .back-btn {
    display: block;
    margin-bottom: 24px;
    color: $grey-200;
    text-decoration: none;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
    padding: 4px 0;

    &:hover {
      color: $grey-300;
    }

    svg {
      margin-right: 8px;
    }
  }

  .post-header {
    display: flex;
    flex-direction: row;
  }

  table {
    width: 100%;
    line-height: 2;

    td {
      padding: 7px;
    }
  }

  .date {
    text-align: left;
    font-size: 16px;
    margin-bottom: 24px;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  .title {
    font-size: 38px;
    text-align: left;
    font-weight: 900;
    margin-top: 4px;
    margin-bottom: 32px;

    text-transform: unset;
    letter-spacing: unset;
    color: $grey-900;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 26px;
      margin-bottom: 18px;
    }
  }

  .series-image {
    margin-right: 32px;
  }

  .post-content {
    font-size: 17px;
    line-height: 1.35em;
  }

  .meta {
    font-size: 18px;
    padding: 3px 0 3px 0;

    svg {
      margin-right: 8px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
    }
  }

  .meta + .meta {
    margin-top: 6px;
  }

  .post-details-main {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;
      margin-top: 24px;

      .series-image {
        margin-bottom: 4px;
      }

      .series {
        display: block;
        margin-bottom: 18px;
        font-size: 15px;
      }
    }
  }

  .video-embed {
    margin: 10px 0;

    width: 100%;
    padding-top: 56.25%;
    position: relative;

    iframe {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .nav {
    padding-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.right {
      justify-content: flex-end;
    }

    .nav-prev {
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .nav-next {
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
