@import "../../styles/colours.scss";
@import "../../styles/variables.scss";

#bkk {
  h2 {
    color: $bkk-light;
  }

  h4 {
    color: $tan-700;
  }

  p + h4 {
    margin-top: 48px;
  }

  h5 {
    color: $grey-600;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .red {
    background-color: $bkk;

    &,
    h2,
    h3 {
      color: white;
    }
  }

  .hero .hero-image {
    object-position: right;
  }

  .bkk-ad {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      margin-top: 30px;
      max-width: 1050px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      img {
        width: 95vw;
        height: auto;
        object-fit: contain;
        margin: 5px;
      }
    }
  }

  .bkk-award {
    p {
      font-size: 15px;
      font-style: italic;
      margin-top: 10px;
    }
  }

  .bkk-award + .bkk-award {
    margin-top: 18px;
  }

  .info-btn-selected {
    color: $bkk-light !important;
  }

  #core-faith-values {
    margin-bottom: 24px;
  }
}

#bkk-testimonials-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .testimonial-btn {
    font-size: 24px;
    width: 64px;
    height: 64px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.desktop {
      @media screen and (max-width: $mobile-breakpoint) {
        display: none;
      }
    }

    //&.mobile {
    //  display: none;
    //  @media screen and (max-width: $mobile-breakpoint) {
    //    //width: 32px;
    //    //height: 32px;
    //    //font-size: 12px;
    //  }
    //}

    z-index: 5;

    &:hover {
      background-color: $grey-050;
    }
  }
}

.bkk-testimonials-mobile-btns {
  display: none;
  @media screen and (max-width: $mobile-breakpoint) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12px;

    .testimonial-btn {
      width: 48px;
      height: 48px;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#bkk-testimonials-slider-images {
  height: 80vh;
  width: 80vh;
  max-height: 600px;
  position: relative;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: $mobile-breakpoint) {
    height: 90vw;
  }

  .before,
  .current,
  .after {
    position: absolute;
    max-width: 100%;
    max-height: 100%;

    transition: all 0.3s;
  }

  .hidden {
    display: none;
  }

  .before {
    left: -50%;
    transform: translate(-50%, 0);
    opacity: 0;
  }

  .current {
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 1;
  }

  .after {
    left: 150%;
    transform: translate(-50%, 0);
    opacity: 0;
  }
}

#principals-message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 36px;

  img {
    max-width: 320px;
    height: auto;
  }

  #principals-message-content {
    margin-left: 48px;

    h2 {
      margin-top: 0;
    }

    #sign-off {
      text-align: right;
      line-height: 1.5em;
      font-style: italic;
      margin-top: 24px;

      .subtitle {
        font-size: 15px;
      }
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    padding: 24px;
    align-items: center;

    img {
      max-width: unset;
      width: auto;
      max-height: 45vh;
    }

    #principals-message-content {
      margin-left: 0;
      margin-top: 32px;
    }
  }
}

#bkk-awards-list {
  margin-top: 48px;
}

#bkk-class-types {
  //max-width: 500px;
  //margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .timeline-item-date {
    width: 160px;
    text-align: right;

    &::after {
      left: 120px;
    }
  }

  .timeline-item-text {
    margin-left: 32px;
    width: 120px;
  }
}

#bkk-parents-portal-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 240px 0;

  h2 {
    margin-top: 0;
    margin-bottom: 32px;
  }

  .btn {
    svg {
      margin-left: 18px;
    }
  }
}
