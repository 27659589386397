@import "../../styles/colours";
@import "../../styles/variables";

.timeline-item {
  display: flex;
  flex-direction: row;

  .timeline-item-date {
    font-weight: bold;
    width: 80px;
    line-height: 1.5em;
    position: relative;
    color: $tan-800;

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: calc(100% - 12px);
      left: 20px;
      top: 31px;
      background-color: $tan-200;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      width: 50px;

      &::after {
        left: 15px;
        top: 27px;
      }
    }
  }

  .timeline-item-text {
    line-height: 1.5em;
    flex: 1;
  }

  & + .timeline-item {
    margin-top: 24px;
  }

  &:last-of-type {
    .timeline-item-date {
      &::after {
        content: unset;
      }
    }
  }
}
