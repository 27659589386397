@import "../../../styles/colours";
@import "../../../styles/variables";

.similar-posts {
  width: 100%;
  max-width: 1200px;
  margin: 52px auto;
  padding: 18px;

  border-top: 1px solid $grey-100;

  h3 {
    color: $blue-400;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 0 16px;
    margin: 12px auto;
  }

  .similar-posts-items {
    display: flex;
    flex-direction: row;

    overflow: auto;
    column-gap: 6px;

    @media screen and (max-width: $mobile-breakpoint) {
      column-gap: 4px;
      margin-bottom: 32px;
    }
  }

  .similar-post-item {
    padding: 10px 16px 16px 16px;
    font-size: 14px;
    flex: 1;
    transition: all 0.3s;

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 6px 9px;
      font-size: 13px;
      min-width: 90px;
    }
  }

  .similar-post-active {
    background-color: $grey-050;
    color: $grey-400;
    border: 1px solid $grey-050;
  }

  .similar-post-link {
    text-decoration: none;
    border: 1px solid $blue-100;
    font-weight: normal;

    &:hover {
      background-color: $blue-050;
    }
  }

  .my-node-enter {
    white-space: nowrap;
    flex: 0;
    opacity: 0;
    overflow: hidden;
  }
  .my-node-enter-active {
    white-space: nowrap;
    flex: 1;
    opacity: 1;
  }
  .my-node-exit {
    white-space: nowrap;
    flex: 1;
    opacity: 1;
  }
  .my-node-exit-active {
    white-space: nowrap;
    flex: 0;
    opacity: 0;
    overflow: hidden;
  }
}
