@import "../../styles/colours.scss";

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag {
  background: $grey-100;
  color: $grey-600;
  border-radius: 24px;
  padding: 3px 10px;
  font-size: 14px;

  margin-right: 4px;
  margin-bottom: 4px;
}
