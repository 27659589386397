@import "../../styles/colours.scss";
@import "../../styles/variables.scss";

$hero-text-padding: 72px; // for aligning text
$mobile-hero-height: 80vh;

.hero {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;

  // to take up some space and show that an image is loading
  background-color: $grey-050;

  &.hero-with-children {
    @media screen and (max-width: $mobile-breakpoint) {
      // ensure that some banners will remain visible
      min-height: $mobile-hero-height;

      .hero-image {
        min-height: $mobile-hero-height;
      }
    }
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    z-index: 0;
    display: block;
  }

  .hero-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;

    .frontpage-segment {
      height: 100%;
    }
  }

  .hero-text-container {
    font-size: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &.align-top-left {
      padding-top: $hero-text-padding;
      padding-left: $hero-text-padding;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &.align-top-right {
      padding-top: $hero-text-padding;
      padding-right: $hero-text-padding;
      align-items: flex-start;
      justify-content: flex-end;
    }

    &.align-bottom-left {
      padding-bottom: $hero-text-padding;
      padding-left: $hero-text-padding;
      align-items: flex-end;
      justify-content: flex-start;
    }

    &.align-bottom-right {
      padding-bottom: $hero-text-padding;
      padding-right: $hero-text-padding;
      align-items: flex-end;
      justify-content: flex-end;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 72px;
      padding: 24px !important;
      align-items: center !important;
      justify-content: center !important;
      text-align: center;
    }
  }

  .hero-text {
    font-family: Pharllos, sans-serif;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

    &.title {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 46px !important;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
    }
  }
}
