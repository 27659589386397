@import "../../styles/variables.scss";
@import "../../styles/colours.scss";

$column-padding: 24px;

#whats-on {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 42px $column-padding;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  & h2 {
    margin-top: 0;
  }

  #upcoming-series {
    border: 1px solid $grey-100;
    padding: 18px 24px;
    border-radius: 8px;
    margin-bottom: 16px;

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 14px 18px;
    }

    table {
      margin-top: 18px;
      width: 100%;
      border-spacing: 0;
    }

    td {
      border-top: 1px solid $grey-100;
    }

    th,
    td {
      padding: 2px 6px;
    }
  }

  #tithing,
  #latest-sermon,
  #announcements {
    margin: 0 $column-padding;
  }

  #whats-on-left-bar {
    width: 320px;
    box-sizing: border-box;
    margin-right: 32px;
  }

  #announcements > .row {
    @media screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }
  }

  #tithing {
    margin-bottom: 20px;
    padding: 18px;
    background-color: $grey-050;

    h2 {
      margin-bottom: 0;
      font-size: 18px;
    }

    h3 {
      font-size: 15px;
      margin: 0;
    }

    p {
      font-size: 13px;
      line-height: 1.25em;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: unset;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }

    .MuiAccordionDetails-root {
      p {
        margin: 0;
      }

      p + p {
        margin-top: 12px;
      }
    }

    .tithing-paynow {
      img {
        width: 100%;
      }
    }
  }

  #latest-sermon {
    background-color: $tan-050;
    padding: 24px;

    #latest-sermon-image {
      width: 100%;
    }

    #sermon-date {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 14px;
      color: $tan-600;
      margin-top: 18px;
    }

    #sermon-title {
      font-weight: bold;
      font-size: 20px;
      margin-top: 10px;
    }

    #sermon-speaker {
      font-style: italic;
      margin-top: 3px;
      margin-bottom: 24px;
    }

    .sermon-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .btn + .btn {
        margin-top: 6px;
      }
    }
  }

  #announcements {
    flex: 1;
    // max-width: 1000px;

    .breadcrumbs {
      width: 100%;
      padding: 15px 0;

      a {
        text-decoration: none;
        color: $blue-500;
      }
    }
  }

  @media screen and (max-width: 1040px) {
    flex-direction: column;
    align-content: center;

    #announcements {
      margin-top: 20px;
    }

    #whats-on-left-bar {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      margin-right: 0;
      width: 100%;

      #latest-sermon {
        margin: unset;
        padding: 20px;
        width: 500px;
      }
    }

    #tithing {
      margin: unset;
      padding: 20px;
      width: 100%;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    padding: 18px;

    #tithing,
    #latest-sermon,
    #announcements {
      width: unset;
      margin: 0;
      margin-bottom: 48px;
      max-width: 90vw;
    }
  }
}

#psalm96fm {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  background-color: #996979;
  border-radius: 8px;

  padding-left: 24px;
  margin-bottom: 24px;

  #blurb {
    width: 180px;

    color: white;
    font-weight: bold;
    font-size: 0.8em;
    line-height: 1.2em;
  }

  #player {
    flex: 1;
    margin-bottom: -4px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;

    #blurb {
      width: unset;
      padding: 18px 18px 0 0;
      font-size: 0.9em;
    }

    #player {
      margin-bottom: unset;
    }
  }
}
