$mobile-breakpoint: 980px;

// Navbar
$navbar-height: 120px;
$navbar-small-height: 64px;

$burger-width: 24px;
$burger-height: 21px;
$burger-right-margin: 16px;
$burger-top-margin: ($navbar-small-height - $burger-height) * 0.5;

$input-padding-height: 12px;
