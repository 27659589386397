// For big page-wide transitions
.transition-container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;

  & > div {
    transition: all 0.7s;
  }
}

.page-fade-enter {
  opacity: 0;
  position: absolute;
}

.page-fade-enter-active {
  opacity: 1;
  position: absolute;
}

.page-fade-exit {
  opacity: 1;
  position: absolute;
}

.page-fade-exit-active {
  opacity: 0;
  position: absolute;
}

// For fading small components in and out
//.component-appear,
//.component-appear-active,
//.component-appear-done {
//  opacity: 0;
//}

.component-fade-enter {
  opacity: 0;
}

.component-fade-enter-active {
  opacity: 1 !important;
  transition: all 0.3s;
}

.component-fade-enter-done {
  opacity: 1 !important;
}

.component-fade-exit {
  opacity: 1;
}

.component-fade-exit-active {
  opacity: 0;
  transition: all 0.3s;
}

.component-fade-exit-done {
  display: none;
}

// For vertical
.vertical-enter {
  transform: scaleY(0);
}

.vertical-enter-active {
  transform: scaleY(1);
  transition: all 0.3s;
}

.vertical-exit {
  transform: scaleY(1);
}

.vertical-exit-active {
  transform: scaleY(0);
  transition: all 0.3s;
}

// For horizontal
.horizontal-enter {
  transform: scaleX(0);
  opacity: 0;
}

.horizontal-enter-active {
  transform: scaleX(1) !important;
  opacity: 1;
  transition: all 0.2s;
}

.horizontal-enter-done {
  transform: scaleX(1) !important;
}

.horizontal-exit {
  transform: scaleX(1) !important;
  opacity: 1;
}

.horizontal-exit-active {
  transform: scaleX(0) !important;
  opacity: 0;
  transition: all 0.2s;
}

.horizontal-exit-done {
  transform: scaleX(0) !important;
}
