@import "../../styles/colours.scss";
@import "../../styles/variables.scss";

.listing-with-search {
  .listing-results {
    margin-top: 24px;
    margin-bottom: 32px;
    min-height: 160px;

    .no-data {
      margin-top: 0;
      padding-top: 64px;
    }
  }

  .grid-layout {
    display: grid;
    grid-gap: 24px 12px;
    width: 100%;

    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 1370px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1050px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    text-decoration: none;
    color: black;
    font-weight: unset;
    transition: all 0.3s;
    border-radius: 3px;

    &.announcements,
    &.testimonies {
      &:hover {
        background-color: $grey-050;
      }
    }

    @media screen and (max-width: 800px) {
      padding: 0;
      //margin-bottom: 24px;
    }

    .pinned-icon {
      color: $tan-300;
      font-size: 14px;
      padding-right: 4px;
    }

    .card-content {
      display: flex;
      flex-direction: row;
      width: 100%;

      .card-img {
        object-fit: cover;
        width: 124px;
        height: 124px;

        @media screen and (max-width: $mobile-breakpoint) {
          width: 84px;
          height: 84px;
        }
      }

      .card-text {
        margin-left: 18px;
        flex: 1;

        .card-date {
          font-size: 12px;
          margin-bottom: 12px;

          @media screen and (max-width: $mobile-breakpoint) {
            font-size: 11px;
            letter-spacing: 1px;
          }
        }

        a:hover {
          text-decoration: underline !important;
        }

        .series {
          font-size: 14px;
          margin-bottom: 4px;
          font-style: normal;

          svg {
            color: $blue-500;
          }

          a {
            font-weight: 500;
          }
        }

        .card-title {
          display: block;
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 18px;

          a {
            text-decoration: none;
            color: black;
          }
        }

        .card-blurb {
          overflow: hidden;
          color: $grey-600;
          font-size: 14px;

          p:first-of-type {
            margin-top: 0;
          }

          p:last-of-type {
            margin-bottom: 0;
          }

          @media screen and (max-width: $mobile-breakpoint) {
            p {
              margin: 0;
            }
          }
        }

        .card-details {
          color: $grey-600;
          font-size: 14px;
          margin-top: 6px;

          display: flex;
          flex-direction: row;
        }

        .card-details-speaker {
          color: $grey-600;
          text-decoration: none;
          font-weight: normal;
        }

        .card-details-speaker + .card-details-speaker::before {
          content: ", ";
        }

        .card-details-passage {
          color: $tan-700;
        }

        .card-details.tags {
          margin-top: 10px;
        }

        svg {
          margin-right: 6px;
          margin-top: 2px;
        }

        a {
          flex: 1;
        }
      }
    }
  }

  .card + .card {
    @media screen and (max-width: 800px) {
      padding-top: 24px;
      border-top: 1px solid $grey-050;
    }
  }

  .paginate-stack {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .pagination {
      width: unset;
    }

    .per-page {
      display: flex;
      align-items: center;
    }
  }
}

.no-data {
  text-align: center;
  margin: 72px 0;
  font-size: 16px;
  color: $grey-200;
}

#testimonies-container {
  .grid-layout {
    grid-template-columns: repeat(5, 1fr);

    @media screen and (max-width: 1370px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .card-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 800px) {
      .card + .card {
        padding-top: 0;
        border-top: 0;
      }
    }
  }
}
