@import "../../styles/colours";
@import "../../styles/variables";

#missions-page {
  #three-steps {
    display: flex;
    flex-direction: row;

    .step {
      padding: 20px;
      width: 250px;
      display: flex;
      flex-direction: column;

      text-align: center;

      svg {
        color: $tan-500;
      }

      h4 {
        text-transform: uppercase;
      }

      p {
        font-size: 16px;
      }
    }

    .arrow {
      margin-top: 35px;

      svg {
        color: $grey-100;
      }
    }

    @media screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;
      align-items: center;

      .arrow {
        display: none;
      }
    }
  }

  #three-steps-wrapper {
    @media screen and (max-width: $mobile-breakpoint) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .arrow {
        display: none;
      }
    }
  }

  #speakers-and-opportunities {
    .content-row {
      border: 1px solid $grey-100;
      padding: 18px;
      border-radius: 4px;
    }

    .content-row + .content-row {
      margin-top: 32px;
    }

    .date {
      font-weight: lighter;
      font-size: 0.85em;
    }

    .row-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;
      margin-bottom: 24px;

      font-weight: bold;
      line-height: 1.2em;

      .speaker-img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
      }

      .organisation {
        color: $grey-400;
      }
    }

    .list-header {
      color: $tan-600;
      font-weight: bold;
      margin-bottom: 12px;
    }

    ul, ol {
      margin-top: 0;
    }
    //
    //li + li {
    //  margin-top: 4px;
    //}
  }
}