@import "./colours.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;0,900;1,400;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap");
@import "./variables.scss";

@font-face {
  font-family: "Pharllos";
  src: url("pharllos.woff") format("woff"); /* IE9 Compat Modes */
}

.Toastify__toast-body {
  font-family: "Source Sans Pro", sans-serif;
}

html {
  height: 100%;
  min-height: 100%;
  font-family: "Source Sans Pro", sans-serif !important;
  color: $grey-700;
}

.rtTooltipBody,
.rtTooltipHeader {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

.rtBibleRef {
  white-space: nowrap;
}

body {
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 15px;
  }
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;

  margin-top: $navbar-height;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: $navbar-small-height;
  }

  @media print {
    margin-top: 0;
  }
}

h2 {
  color: $blue-600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.35em;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 18px;
  }
}

h3 {
  color: $grey-700;
  margin-top: 36px;
  margin-bottom: 22px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

.btn,
input {
  font-size: 16px;
}

.btn {
  display: block;
  padding: $input-padding-height 32px;
  min-width: 160px;
  background: unset;

  // font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;

  cursor: pointer;
  transition: all 0.3s;

  &.btn-border-white {
    border: 2px solid white;
    color: white;

    &:hover {
      background-color: rgba(255, 255, 255, 0.17);
    }
  }

  &.btn-primary {
    border: 1px solid $btn;
    background-color: $btn;
    color: $btn-text;

    &:hover {
      background-color: $btn-hover;
    }
  }

  &.btn-secondary {
    border: 1px solid $blue-500;
    color: $blue-500;

    &:hover {
      border: 1px solid $blue-200;
      color: $blue-300;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 14px;
    min-width: 90px;
  }
}

.MuiSelect-select,
input {
  padding: $input-padding-height 14px !important;
}

#public-viewport input {
  border: 1px solid $grey-100;

  font-size: 16px;
  transition: all 0.3s;

  &:focus {
    outline: none;
    border: 1px solid $blue-300;
  }

  &::placeholder {
    color: $grey-300;
  }
}

.verse {
  background-color: $grey-050;
  color: $grey-600;
  padding: 18px 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 1.5em;
  border-radius: 3px;
  font-style: italic;
  font-family: "Source Serif Pro", sans-serif;

  .verse-ref {
    text-align: right;
    opacity: 0.6;
    margin-top: 12px;
    font-size: 16px;
  }
}

.purple-back {
  color: $purple;
}

.bottom-text {
  margin-top: 36px;
  font-size: 13px;
  font-style: italic;
  opacity: 0.65;
}

.white-card {
  padding: 72px 24px;
}

.content-holder {
  padding: 32px 24px 72px 24px;
  margin: 0 auto;
  max-width: 920px;
  box-sizing: border-box;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 18px 16px 24px 16px;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 1.5em;
  }
}

a {
  font-weight: bold;
  color: $blue-400;
}

p,
td,
th,
li {
  line-height: 1.5em;
}

th {
  text-align: left;
}

.files-image {
  max-width: 100%;
}

.docs {
  padding-top: 20px;

  .docs-title {
    font-size: 21px;
    font-weight: bold;
  }

  .docs-card {
    margin-top: 10px;

    @media screen and (max-width: $mobile-breakpoint) {
      width: 120px;
      height: 100px;
    }

    .docs-card-content {
      margin: 0 auto;
      text-align: center;

      .docs-filename {
        padding: 10px;
      }
    }
  }
}

.spacer {
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 940px) {
    &.admin-mobile-column {
      flex-direction: column;
    }
  }
}

.date {
  color: $grey-500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.series {
  font-style: italic;

  &,
  a {
    text-decoration: none;
    color: $blue-500;

    &:hover {
      text-decoration: underline;
    }
  }
}

#public-viewport {
  background-color: $navbar-solid;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

}

.series-image {
  flex: 1;
  max-width: 500px;

  img {
    width: 100%;
  }
}

.deemph {
  opacity: 0.8;
  font-style: italic;
}
