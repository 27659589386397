@import "../../styles/colours.scss";
@import "../../styles/variables.scss";

.who-is-jesus {
  background-color: $who-is-jesus-background;
  .content-holder {
    h2 {
      color: white;
    }
    color: rgba(255, 255, 255, 0.8);

    .comic-strip {
      padding: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;

      .comic {
        width: 270px;
      }

      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 14px;
        padding: 36px 24px;

        .comic {
          width: 250px;
        }
      }
    }
  }
}
