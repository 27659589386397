@import "../../styles/colours.scss";
@import "../../styles/variables.scss";

#public-viewport {
  #hamburger-menu {
    position: absolute;
    display: none;
    top: 0;
    left: 0;

    @media screen and (max-width: $mobile-breakpoint) {
      display: block;
    }
  }

  .bm-burger-button {
    position: fixed;
    width: $burger-width;
    height: $burger-height;
    right: $burger-right-margin;
    top: $burger-top-margin;
  }

  .bm-burger-bars {
    background: $nav-text;
    border-radius: 2px;
    transition: all 0.3s;
  }

  /* Color/shape of burger icon bars on hover*/

  .bm-burger-bars-hover {
    background: $nav-hover;
  }

  /* Position and sizing of clickable cross button */

  .bm-cross-button {
    height: 32px;
    width: 32px;
  }

  /* Color/shape of close button cross */

  .bm-cross {
    background: $nav-hover;
  }

  .bm-menu {
    background-color: $navbar-solid;
    margin-left: 12px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
    padding: 18px 0;
    box-sizing: border-box;

    & > a + a,
    .burger-submenu + .burger-submenu,
    & > a + .burger-submenu,
    .burger-submenu + a {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    a {
      padding: 18px 24px;
      font-size: 17px;
      text-decoration: none;
      color: $blue-500;
      font-weight: bold;
      transition: all 0.2s;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;

      &.active {
        color: $blue-900;
      }
    }

    a:hover {
      color: $blue-700;
      background-color: $navbar-hover;
    }

    .burger-submenu {
      padding: 18px 0 8px 0;

      .subheader {
        font-size: 14px;
        color: $tan-700;
        font-weight: bold;
        padding: 0 24px 4px 24px;
      }

      a {
        padding: 10px 24px;
        opacity: 0.85;
      }
    }
  }
}

#public-navbar {
  text-transform: uppercase;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition-duration: 0.2s;
  font-size: 16px;
  line-height: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media print {
    display: none;
  }

  .logo {
    float: left;
    display: flex;
    align-items: center;
  }

  img {
    transition: all 0.3s;
  }

  #nav-title {
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 4px;
    margin-bottom: 10px;
    margin-right: -4px;
  }

  background-color: $navbar;

  &.nav-at-top {
    background-color: $navbar-solid;
    // background-color: rgba(255, 255, 255, 0.7);

    img {
      width: 124px;
      // background-color: white;
      // border-radius: 300px;

      @media screen and (max-width: $mobile-breakpoint) {
        width: 64px;
      }
    }
  }

  &.nav-not-at-top {
    background-color: $navbar;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    img {
      width: 80px;

      @media screen and (max-width: $mobile-breakpoint) {
        width: 64px;
      }
    }

    #nav-title {
      display: none;
    }
  }

  #nav-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 48px;

    @media screen and (max-width: $mobile-breakpoint) {
      #nav-title,
      #nav-links {
        display: none;
      }
    }
  }

  #nav-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    .link {
      font-weight: bold;
      background-color: transparent;
      text-decoration: none;
      padding: 8px 24px;
      font-size: 16px;
      color: $blue-500;
      display: inline-block;
      transition: all 0.3s;
      cursor: pointer;
      position: relative;

      a {
        text-decoration: none;
        color: $blue-500;
      }

      &:hover {
        color: $blue-700;
      }

      &.active {
        color: $blue-900;
      }

      svg {
        margin-left: 8px;
      }

      .dropdown-menu {
        position: absolute;
        top: 32px;
        display: flex;
        flex-direction: column;
        margin: 0 -18px;
        z-index: 100;

        .dropdown-link {
          text-decoration: none;
          font-weight: bold;
          padding: 12px 18px;
          min-width: 138px;
          transition: all 0.3s;

          background-color: $navbar-solid;
          color: $blue-500;

          &:hover {
            background-color: $navbar-solid-hover;
            color: $blue-700;
          }

          &.active {
            color: $blue-800;
          }
        }
      }
    }

    .last-link {
      padding-right: 0;
    }
  }
}
