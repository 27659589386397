@import "../../../styles/colours";
@import "../../../styles/variables";

.paginate-stack {
  .pagination {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    //margin-right: 40px;

    //.active {
    //background-color: $blue-050;
    //color: $tan-500;
    //text-decoration: underline;
    //}

    li {
      //margin: 3px;
      //background-color: $grey-050;
      cursor: pointer;
      list-style-type: none;
      font-size: 15px;

      a {
        display: inline-block;
        padding: $input-padding-height 12px;
        line-height: 1em;
        font-weight: 400;
        color: $blue-300;

        transition: all 0.3s;
        border-radius: 3px;

        &:hover {
          background-color: $blue-050;
        }
      }

      &.paginate-break {
        a {
          color: $grey-100;
          cursor: default;

          &:hover {
            background-color: unset;
          }
        }
      }

      &.disabled {
        a {
          color: $grey-100;
          cursor: default;

          &:hover {
            background-color: unset;
          }
        }
      }

      &.active {
        //background-color: $blue-600;

        a {
          font-weight: 900;
          //color: white;
          color: $blue-600;
        }
      }
    }
  }

  //@media screen and (max-width: $mobile-breakpoint) {
  //  width: 100%;
  //  justify-content: center;
  //}
}
