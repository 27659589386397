@import "../../styles/colours.scss";
.loading {
  width: 100%;
  height: 100%;
  min-height: 40vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $grey-300;

  div {
    margin-bottom: 18px;
  }
}
