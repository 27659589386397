@import "../../../styles/variables";
@import "../../../styles/colours";

#people {
  #team-selector {
    margin: 72px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @media screen and (max-width: $mobile-breakpoint) {
      margin: 36px 0;
      gap: 12px;
      flex-direction: column;

      .btn {
        width: 180px;
      }
    }
  }

  #people-council {
    width: 100%;

    h1 {
      margin-top: 92px;
      text-align: center;
      font-family: "Pharllos", sans-serif;
      font-size: 64px;
      color: $tan-600;

      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 48px;
        margin: 54px 18px 32px 18px;
      }
    }
  }

  #people-council-names {
    padding: 16px;
    margin: 32px auto;
    max-width: 800px;

    display: flex;
    flex-direction: row;

    .name-list {
      flex: 1;

      .ministry-name {
        color: $grey-400;
      }
    }

    @media screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 12px;
    }

    ol {
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  .people-card {
    .people-card-name {
      font-weight: bold;
      height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      line-height: 1.1em;
      margin-bottom: 2px;
    }

    .people-card-role {
      font-style: italic;
      height: 30px;
      text-align: center;
    }
  }
}
