@import "../../styles/colours";

.cta {
  font-size: 0.9em;
  padding: 12px;
  margin-top: 16px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  color: $blue-700;
  background-color: $blue-050;
  margin-bottom: 12px;
}