#whats-on {
  .grid-layout {
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 1150px) {
      grid-template-columns: 1fr;
    }
  }
}
