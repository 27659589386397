@import "../../styles/colours";
@import "../../styles/variables";

#for-members {
  margin-top: 32px;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.2em;
  }

  h3 {
    font-size: 1.05em;
    margin-bottom: 12px;
  }

  #members-nav {
    display: flex;
    flex-direction: row;
    gap: 1px;
    margin-bottom: 64px;

    overflow-y: auto;

    a {
      flex: 1;
      text-align: center;
      text-decoration: none;
      font-size: 0.8em;
      background-color: $tan-050;
      color: $tan-700;

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 12px;

      transition: all 0.3s;

      &:hover {
        background-color: $tan-100;
        color: $tan-800;
      }
    }
  }

  .member-section {
    .header {
      align-items: center;
      margin-bottom: 12px;

      svg {
        color: $tan-600;
      }

      h2 {
        flex: 1;
      }

      .to-top {
        margin-left: 24px;
        text-decoration: none;
        font-size: 14px;
        color: $tan-400;
      }
    }

    .section-icon {
      width: 54px;
    }

    .section-body {
      margin-left: 54px;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0;
      }

      p + p {
        margin-top: 12px;
      }
    }
  }

  .member-section + .member-section {
    margin-top: 64px;
  }

  .frequency,
  .cta {
    font-size: 0.9em;
    padding: 12px;
    margin-top: 16px;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .frequency {
    color: $grey-500;
    background-color: $grey-040;
  }

  .frequency + .cta {
    margin-top: 8px;
  }
}
