@import "../../styles/colours";

.sermon-meta-speaker {
  &:hover {
    text-decoration: underline;
  }
}

.sermon-meta-speaker + .sermon-meta-speaker::before {
  content: ", ";
}

#sermon-post-services {
  margin-top: 54px;
  background-color: $tan-050;
  padding: 8px;

  .row {
    .btn + .btn {
      margin-left: 8px;
    }
  }

  .video-embed-not-yet {
    text-align: center;
    margin: 120px 0;
    color: $grey-200;
    font-size: 14px;
    font-style: italic;
  }
}
