@import "../../styles/colours.scss";
@import "../../styles/variables.scss";

.frontpage-segment {
  font-size: 55px;
  text-align: center;
  color: $nav-hover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stylised-header,
  .normal-header {
    padding: 32px;
  }

  .stylised-header {
    text-align: center;
    color: white;
    font-size: 92px;
    line-height: 1.3em;
    max-width: 720px;
    margin: 0 auto;

    .deemphasise {
      color: $tan-200;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 64px;
    }

    @media screen and (max-width: 400px) {
      font-size: 48px;
    }
  }

  .normal-header {
    text-transform: uppercase;
    font-weight: bold;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 42px;
    }
  }
}

#frontpage-hero {
  color: white;
  height: 100%;
  width: 90vw;
  position: relative;
  margin: 0 auto;

  .frontpage-title {
    font-size: 160px;
    position: absolute;
    top: 72px;
    left: 96px;
  }

  #service-card {
    // box properties
    background-color: $card-500;
    padding: 24px;
    position: absolute;
    right: 64px;
    bottom: 64px;
    max-width: 400px;

    // for text
    text-align: center;

    .service-link {
      padding: $input-padding-height 32px;
      text-decoration: none;

      cursor: pointer;
      transition: all 0.3s;

      border: 2px solid white;
      color: white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.17);
      }
    }

    #service-sunday {
      font-size: 26px;
      font-weight: bold;
      text-transform: uppercase;
    }

    #service-time {
      font-size: 22px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    #service-title {
      font-size: 22px;
      margin-bottom: 8px;
    }

    #service-speaker {
      font-size: 18px;
      font-style: italic;
      opacity: 0.7;
    }

    // for the buttons
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card-btn-wrapper {
      margin: 0;
      padding: 0;
      margin-top: 10px;
    }

    .btn-border-white + .btn-border-white {
      margin-top: 6px;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .frontpage-title {
      position: relative;
      top: 0;
      left: 0;
      font-size: 72px;
      margin-top: 32px;
      margin-bottom: 12px;
      text-align: center;
    }

    #service-card {
      position: relative;
      bottom: 0;
      right: 0;
      margin: 18px;
      box-sizing: border-box;
      width: calc(100vw - 36px);
    }
  }
}
